/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">
              {{ cvCardTitle }}
            </h4>
          </template>
          <template>
            <p v-if="!propOpenedInModal">
              {{ cvCardSubHeader }}
            </p>
            <form action="#">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label
                    for="validationpost_title">
                    {{cvPostTitleLabel}}</label>
                  <input
                    v-model="vmPostFormData.post_title"
                    type="text"
                    class="form-control"
                    required/>
                </div><div class="col-md-12 mb-3">
                  <label
                    for="validationpost_desc">
                    {{cvPostDescLabel}}</label>
                  <input
                    v-model="vmPostFormData.post_desc"
                    type="text"
                    class="form-control"
                    required/>
                </div><div class="col-md-12 mb-3">
                  <label
                    for="validationpost_image">
                    {{cvPostImageLabel}}</label>
                  <b-form-file
                    type="file"
                    class="form-control"
                    required
                    @change="onChange"/>
                </div><div class="col-md-12 mb-3">
                  <label
                    for="validationpost_type">
                    {{cvPostTypeLabel}}</label>
                  <input
                    v-model="vmPostFormData.post_type"
                    type="number"
                    min="1"
                    max="4"
                    class="form-control"
                    required/>
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus"
                        type="button"
                        class="btn btn-primary"
                        :disabled="cvLoadingStatus">
                  <b-spinner label="Spinning">
                  </b-spinner>
                </button>
                <button v-else
                        type="button"
                        class="btn btn-primary"
                        @click="postAdd()">
                  {{ cvSubmitBtn }}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>

<script>
import { Posts } from "../../../FackApi/api/post"
import ApiResponse from "../../../Utils/apiResponse.js"

export default {
  name: "PostAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cvCardTitle: "Add post",
      cvCardSubHeader: "Add post ",
      cvSubmitBtn: "Add",
      cvPostTitleLabel: "Post Title",
      cvPostDescLabel: "Post Description",
      cvPostImageLabel: "Post Image",
      cvPostTypeLabel: "Post Type",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Post",
      vmPostFormData: Object.assign({}, this.initFormData()),
      vmPostDesc: null,
      vmPostType: null,
      cvLoadingStatus: false
    }
  },
  mounted () {
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "post_title": "",
        "post_desc": "",
        "post_image": "",
        "post_type": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmPostFormData) {
          if (!this.vmPostFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * postAdd
     */
    async postAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      try {
        this.cvCardTitle = true
        this.vmPostFormData["post_module_obj_id"] = this.userData.user_id
        let postAddResp = await Posts.postAdd(this, this.vmPostFormData)
        await ApiResponse.responseMessageDisplay(this, postAddResp)
        if (postAddResp && !postAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitClosePostAddModal", postAddResp.resp_data.post_data)
        }
        this.vmPostFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at postAdd() and Exception:", err.message)
      }
      finally {
        this.cvCardTitle = false
      }
    },
    /**
     * Onchange
     */
    onChange (e) {
      try {
        let input = e.target
        if (input.files.length > 0 && input.files[0]) {
          this.vmPostFormData.post_image = input.files[0]
          // const reader = new FileReader()
          // reader.onload = (e) => {
          //   this.vmPostFormData.post_image = reader.result
          // }
          // reader.readAsDataURL(input.files[0])
        }
      }
      catch (err) {
        console.error("Exception occurred at onChange() and Exception:", err.message)
      }
    }
  }
}
</script>
