/*eslint-disable */
import request from '../../Utils/curl'
import Utility from "../../Utils/utility"
let Posts = {
  /**
   * postList
   */
  async postList (context, currentPage, filter, moduleId ) {

    try{
      let post_data = new FormData();
    
      post_data.append('filter', JSON.stringify(filter));
      post_data.append('currentPage', currentPage)

      if(moduleId){
        //Get data for this module
        post_data.append('post_module_obj_id', moduleId)
      }

      return await request.curl(context, "post_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at postList() and Exception:',err.message)
    }
  },

  /**
   * postListConsole
   */
  async postListConsole (context) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait....";   
    try{
      let post_data = new FormData();
      return await request.curl(context, "post_list_console", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at postListConsole() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * postView
   */
  async postView (context, postId) {
    try {
      
      let post_data = new FormData();
      post_data.append('post_id', postId);
      return await request.curl(context, "post_view", post_data)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at postView() and Exception:",err.message)
    }
  },

  
  /**
   * postContentView
   */
   async postContentView (context, postId) {
    try {
      
      let post_data = new FormData();
      post_data.append('post_id', postId);
      return await request.curl(context, "post_content_view", post_data)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at postContentView() and Exception:",err.message)
    }
  },

  /**
   * postAdd
   */
  async postAdd (context, postObj) {
    try{
    let post_data = new FormData();
    
    for (let key in postObj) {
      if(key === 'post_module_obj_id' && typeof postObj[key] == 'object'){
        post_data.append(key, postObj[key]['id'])
      }
      else{
        post_data.append(key, postObj[key]);
      }
    }

    return await request.curl(context, "post_add", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at postAdd() and Exception:',err.message)
    }
  },

  /**
   * postEdit
   */
  async postEdit (context, postObj) {
    try{
    let post_data = new FormData();
    
    for (let key in postObj) {
      post_data.append(key, postObj[key]);
    }

    return await request.curl(context, "post_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at postEdit() and Exception:',err.message)
    }
  },

  /**
   * postDelete
   */
  async postDelete (context, postId) {
    try{
    let post_data = new FormData();
    
    post_data.append('post_id', postId);

    return await request.curl(context, "post_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at postDelete() and Exception:',err.message)
    }
  }
}

export {
  Posts
}
